/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-weight: 200;
}

input,
nav-item {
  font-weight: 200;
  line-height: 110%;
}

.bg-image {
  /* The image used */
  background-image: url(./bg.svg);

  /* Full height */
  height: 1000px;

  /* Center and scale the image nicely */
  background-position: center;
  background-position-y: 50px;
  background-repeat: no-repeat;
  background-size: cover;
}
